
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import SymbolList from '@/modules/market/SymbolList.vue'
import TheSymbolButton from '@/modules/membership/signal/components/TheSymbolButton.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSignalEditView',
  components: { Scroller, TheSymbolButton, SymbolList, FullScreen, PageHeader },
})
