
import TheToggleStateButton from '@/modules/market/components/TheToggleStateButton.vue'
import useToggleMonitor from '@/modules/membership/signal/useToggleMonitor'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSymbolButton',
  components: { TheToggleStateButton },
  props: {
    symbol: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return useToggleMonitor(props.symbol.code as string)
  },
})
