/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/7/6 15:00
 * @description
 *   useToggleMonitor.ts of wetrade
 */
import useRequest from '@/hooks/useRequest'
import { saveList } from '@/modules/membership/signal/signal.api'
import * as R from 'ramda'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { shallowRef } from 'vue'

/**
 * 添加/移除监控列表
 * @todo 这块逻辑有重复
 * @param code
 */
const useToggleMonitor = (code: string) => {
  const localList = localGet(keymap.label.monitorList) || ''
  const added = shallowRef(R.includes(code, localList))
  const [save, progress] = useRequest(saveList)

  const toggle = () => {
    let nextList = (localGet(keymap.label.monitorList) || '').split(',')
    if (added.value) {
      nextList = R.reject(R.equals(code), nextList)
    } else {
      nextList = R.prepend(code, nextList)
    }

    const nextCodes = nextList.toString()
    save({
      codes: nextCodes,
      firebaseToken: 100,
    })
      .then(() => {
        added.value = !added.value
        localSet(keymap.label.monitorList, nextCodes)
      })
  }

  return {
    added,
    progress,
    toggle,
  }
}

export default useToggleMonitor
