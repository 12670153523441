import requestResult from '@/common/request/request'

export enum Types {
  L_1S = 11,
  K_5S = 12,
  K_15S,
  K_30S,
  K_5 = 2, // 5M
  K_15,
  K_30,
  K_60,
  K_1D, // 1D
}

export type SignalsListSchema = {
  title: string;
  id: number;
  isChecked: boolean;
  i18nPath: string;
}

export type SignalsSchema = {
  codeList: string[];
  typeList: string[];
}

export const readSignalsList = requestResult<SignalsSchema>('/api/indicatorssign/signswitchandcode')

export const readCodeList = requestResult('/api/indicatorssign/list')

export const saveList = requestResult('/api/indicatorssign/save')

export enum SignalType {
  TC30M = 4, // trend changed in 30 minutes
  TC1D = 6, // trend changed in one day
  TO30M, // trend overbought/sold in 30 minutes
  TO1D // trend overbought/sold in one day
}

export const saveSignal = requestResult('/api/indicatorssign/savesign')

export enum SignalAuthLevel {
  FORBIDDEN,
  MEMBER,
  PRO,
}

export const isauth = requestResult<SignalAuthLevel>('/api/indicatorssign/isauth')
